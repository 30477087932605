(function () {
  'use strict';

  angular
    .module('wbFaq')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('faq', {
        url: '/faq',
        templateUrl: 'wb-faq/views/faq.tpl.html',
        controller: 'FaqCtrl',
        controllerAs: 'Faq'
      });
  }
}());
